import React from 'react';
import hydrate from 'nordic/hydrate';

import '../../pages/identification/_styles.scss';
import Identification from '../../pages/identification/components/Identification';

const props = window.__PRELOADED_STATE__;

props.googleButtonWidth = document.getElementById('login-form__oauth-container')?.offsetWidth;

hydrate(
  <Identification {...props} />,
  document.getElementById('root-app'),
  () => {
    /* Added for e2e tests. Ensures the client got js and can run it */
    if (!props.envIsProduction && window.Cypress) {
      window.appReady = true;
    }
  },
);
