const React = require('react');
const { arrayOf, shape } = require('prop-types');

export class ErrorMessage extends React.Component {
  render() {
    this.styles = {
      margin: '8px 0 20px 0px',
      color: '#ff5a5f',
      marginTop: '5px',
      fontSize: '12px',
    };

    const { errors } = this.props;

    return (
      <div className="input-error">
        {
          errors.map(e => (
            <div style={this.styles}>
              {e.text}
              {e.cause === 'invalid_user_site' ? <a href={e.hints.link}>{e.hints.brand}</a> : null}
            </div>
          ))
        }
      </div>
    );
  }
}

ErrorMessage.propTypes = {
  errors: arrayOf(shape()).isRequired,
};
