const React = require('react');
const { string, bool, arrayOf, shape } = require('prop-types');
const classnames = require('classnames');
const { Head } = require('nordic/head');
const { ProgressIndicatorCircular } = require('@andes/progress-indicator-circular');
const { Text } = require('@andes/typography');
const { ErrorMessage } = require('./ErrorMessage');
const { RecaptchaScript } = require('./Script');
const { ErrorIcon } = require('../Icons/Error');
const { isV2LoginViewMode } = require('../../../services/utils/supports');

const recaptchav2Key = '6LchwzUaAAAAAI3Am_n1zyxszhpA9zA2_BZ9feFH';
const recaptchav3Key = '6LeetcMeAAAAAHBLMG_uCF4A7QLR8ZHg8u4ulR5z';
const recaptchav2TestKey = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';

class Recaptcha extends React.PureComponent {
  constructor(props) {
    super(props);
    const { lang, isRunningTest } = props;

    this.recaptchav2Key = isRunningTest ? recaptchav2TestKey : recaptchav2Key;
    this.recaptchav3Key = recaptchav3Key;

    this.url = isRunningTest
      ? `https://www.recaptcha.net/recaptcha/api.js?onload=onloadCallback&render=${this.recaptchav2Key}&hl=${lang}`
      : `https://www.recaptcha.net/recaptcha/enterprise.js?onload=onloadCallback&render=${this.recaptchav3Key}&hl=${lang}`;
  }

  lowEndMarkup() {
    const { isRunningTest, lang } = this.props;

    return !isRunningTest && (
      <div className="g-recatpcha-no-script">
        <div style={{ width: '302px', height: '422px', position: 'relative' }}>
          <div style={{ width: '302px', height: '422px', position: 'absolute' }}>
            <iframe
              title="fm"
              src={`https://www.recaptcha.net/recaptcha/api/fallback?hl=${lang}&k=${this.recaptchav2Key}`}
              frameBorder="0"
              scrolling="no"
              style={{ width: '302px', height: '422px', borderStyle: 'none' }}
            />
          </div>
        </div>
        <div
          style={{
            width: '300px',
            height: '60px',
            borderStyle: 'none',
            bottom: '12px',
            left: '25px',
            margin: '0px',
            padding: '0px',
            right: '25px',
            background: '#f9f9f9',
            border: '1px solid #c1c1c1',
            borderRadius: '3px' }}
        >
          <textarea
            id="g-recaptcha-response"
            name="g-recaptcha-response"
            className="g-recaptcha-response"
            style={{
              width: '250px',
              height: '40px',
              border: '1px solid #c1c1c1',
              margin: '10px 25px',
              padding: '0px',
              resize: 'none' }}
          />
        </div>
      </div>
    );
  }

  markup() {
    const { required, action, envIsProduction } = this.props;

    return (
      <div>
        { required && (
          <ProgressIndicatorCircular className="recaptcha-spinner" modifier="inline" size="small" />
        ) }
        <div id="g-recaptcha" className="g-recaptcha" />
        <RecaptchaScript
          required={required}
          action={action}
          envIsProduction={envIsProduction}
          keys={{ version2: this.recaptchav2Key, version3: recaptchav3Key }}
          url={this.url}
        />
      </div>
    );
  }

  render() {
    const {
      required,
      cspNonce,
      lowEnd,
      errors,
      action,
      envIsProduction,
      viewMode,
      wordings,
    } = this.props;

    if (isV2LoginViewMode(viewMode)) {
      return (
        <>
          <Head>
            <link rel="preconnect" href="https://www.gstatic.com" />
            <link nonce={cspNonce} rel="preload" href={this.url} as="script" />
          </Head>
          {required && (
          <div className="recaptcha__container">
            <Text size="s">{wordings.label}</Text>
            <div className="recaptcha__widget">
              <ProgressIndicatorCircular
                className="recaptcha__spinner"
                modifier="inline"
                size="small"
              />
              <div id="g-recaptcha" className="g-recaptcha" />
            </div>
            { errors.map(({ text, code }) => (
              <Text
                key={code}
                className="recaptcha__error-message"
                color="negative"
                size="xs"
              >
                <ErrorIcon namespace="recaptcha" />
                {text}
              </Text>
            ))}
          </div>
          )}
          <RecaptchaScript
            required={required}
            action={action}
            envIsProduction={envIsProduction}
            keys={{ version2: this.recaptchav2Key, version3: recaptchav3Key }}
            url={this.url}
          />
        </>
      );
    }

    const containerStyles = classnames([
      'recaptcha-container',
      { 'recaptcha-container--error': required },
    ]);

    return (
      <div className={containerStyles}>
        <Head>
          <link rel="preconnect" href="https://www.gstatic.com" />
          <link nonce={cspNonce} rel="preload" href={this.url} as="script" />
        </Head>
        { (!lowEnd) ? this.markup() : this.lowEndMarkup() }
        { errors && <ErrorMessage errors={errors} /> }
      </div>
    );
  }
}

Recaptcha.propTypes = {
  required: bool.isRequired,
  action: string.isRequired, // Could be 'identification' or 'enter_password'
  errors: arrayOf(shape()),
  lowEnd: bool,
  lang: string,
  isRunningTest: bool.isRequired,
  envIsProduction: bool.isRequired,
  cspNonce: string,
  viewMode: string,
  wordings: shape({
    label: string,
  }),
};

Recaptcha.defaultProps = {
  errors: [],
  lowEnd: false,
  lang: 'es-419',
};

module.exports = Recaptcha;
