/* eslint-disable nordic/no-img-tags */
const React = require('react');
const { arrayOf, string } = require('prop-types');

class DomainPreconnect extends React.Component {
  constructor(props) {
    super(props);
    const { urls } = props;
    this.state = {
      urls,
    };
  }

  render() {
    const { urls } = this.state;
    if (urls) {
      const style = {
        display: 'none',
      };

      return (
        <div>
          { urls.map(url => <img style={style} src={`${url}/preconnect_pixel.gif`} alt="" />) }
        </div>
      );
    } else {
      return null;
    }
  }
}

DomainPreconnect.propTypes = {
  urls: arrayOf(string).isRequired,
};

module.exports = DomainPreconnect;
