const React = require('react');
const { shape, string, bool } = require('prop-types');
const { Button } = require('@andes/button');
const { trackEvent } = require('../../../services/tracks/trackEvent');

const RegistrationLink = (props) => {
  const { flavor, text, url, track, ...buttonProps } = props;
  const handleClickCreateAccount = () => {
    trackEvent(track);
  };

  return (
    <Button
      {...buttonProps}
      data-testid="login-link"
      hierarchy={flavor || 'transparent'}
      fullWidth
      href={url}
      onClick={handleClickCreateAccount}
    >
      {text}
    </Button>
  );
};

RegistrationLink.propTypes = {
  id: string.isRequired,
  url: string.isRequired,
  track: shape().isRequired,
  text: string.isRequired,
  flavor: string,
  fullWidth: bool,
  disabled: bool,
};

module.exports = RegistrationLink;
