// TODO: Renombrar esta folder, de SecurityIssues a SecurityProblem.
import React from 'react';
import PropTypes from 'prop-types';
import { List, ListItem } from '@andes/list';
import SecurityProblemSVG from '../../assets/SecurityProblemSVG';
import { AuthFlow, PLATFORM, loginTypes } from '../../../services/data/enums';
import { isCrossBorderTradeBU, isMshopsBU } from '../../../services/utils/businessUnit';
import { trackEvent } from '../../../services/tracks';
import { getModifiedClassNames } from '@auth/grid-view/lib/utils/classNames';

const AvailablePlatforms = [PLATFORM.ML, PLATFORM.MP];

export const SecurityProblem = ({ link, navigation, platform, wordings, track, disabled, isSocialLogin = false }) => {
  const isLoginFlow = AuthFlow.LOGIN === navigation.parameters.auth_flow;
  const isAvailableForPlatform = AvailablePlatforms.includes(platform.id);
  const shouldBeVisible = isLoginFlow
    && isAvailableForPlatform
    && !isMshopsBU(navigation.login_type)
    && !isCrossBorderTradeBU(platform.siteId)
    && navigation.login_type !== loginTypes.SCP_WALLET;

  const socialModifier = isSocialLogin ? 'social' : undefined;

  if (shouldBeVisible) {
    return (
      <List className={getModifiedClassNames('security-problem', socialModifier)}>
        <ListItem
          disabled={disabled}
          chevron
          href={link.href}
          leftContent={<SecurityProblemSVG />}
          title={wordings.securityProblem}
          size="small"
          onClick={() => trackEvent(track.securityProblem)}
        />
      </List>
    );
  }

  return null;
};

SecurityProblem.propTypes = {
  navigation: PropTypes.shape({
    login_type: PropTypes.string,
    parameters: PropTypes.shape({
      auth_flow: PropTypes.oneOf([AuthFlow.LOGIN, AuthFlow.REAUTH]),
    }),
  }),
  platform: PropTypes.shape({
    id: PropTypes.string,
    siteId: PropTypes.string,
  }),
  link: PropTypes.shape({
    href: PropTypes.string,
  }),
  wordings: PropTypes.shape({
    securityProblem: PropTypes.string,
  }),
  isSocialLogin: PropTypes.bool,
};
