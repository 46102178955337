const React = require('react');
const { number, string, shape, arrayOf } = require('prop-types');
const MeliGA = require('nordic/analytics/meli-ga');
const {
  SHOPS,
  PLATFORM,
  loginTypes,
} = require('../../services/data/enums');
const { isMshopsBU } = require('../../services/utils/businessUnit');

const { GUEST_LOGIN_ML, GUEST_LOGIN_MP, HYBRID_GUEST, LOGIN_CHOICE } = loginTypes;

class LoginGA extends React.Component {
  constructor(props) {
    super(props);
    this.dimensions = this.getDimensions();
    this.isShops = isMshopsBU(props.navigation.login_type);
  }

  getValidationErrors() {
    const { errors, recaptcha } = this.props;
    let validationErrors = '';

    const mappedErrors = errors || [];

    mappedErrors.forEach(error => { validationErrors += `${error.cause},`; });

    if (recaptcha?.required) {
      validationErrors += 'recaptcha_required';
    } else {
      // En caso de que no tenga que agregar el 'recaptcha_required' quitamos la última coma
      validationErrors = validationErrors.slice(0, validationErrors.length - 1);
    }

    return validationErrors.toUpperCase();
  }

  getDimensions() {
    const { experiment, mercadoShopsData, navigation: { login_type } } = this.props;
    const dimensions = {};

    dimensions.errors = this.getValidationErrors();

    if (experiment) {
      dimensions.experiment6 = experiment;
    }

    if (mercadoShopsData) {
      const { id } = mercadoShopsData.shop;
      dimensions.collectorId = id;
    }

    if ([GUEST_LOGIN_ML, GUEST_LOGIN_MP, HYBRID_GUEST, LOGIN_CHOICE].includes(login_type)) {
      dimensions.flowType = login_type.toUpperCase();
    }

    return dimensions;
  }

  getPlatform() {
    const { navigation: { platform_id } } = this.props;

    return this.isShops ? PLATFORM.MS : platform_id.toUpperCase();
  }

  render() {
    const { navigation: { platform_id, site_id }, page } = this.props;

    if (platform_id.match(/ms/i)) {
      return null; // don't track the old MS platform
    }

    const props = {
      siteId: site_id.toUpperCase(),
      platform: this.getPlatform(),
      page,
      section: 'LOGIN',
      business: this.isShops ? SHOPS.GA.BUSINESS : 'MARKETPLACE',
      pageVertical: 'CORE',
      dimensions: this.dimensions,
    };

    return (
      <MeliGA {...props} />
    );
  }
}

LoginGA.propTypes = {
  navigation: shape().isRequired,
  page: string.isRequired,
  experiment: string,
  errors: arrayOf(shape()),
  recaptcha: shape(),
  mercadoShopsData: shape({
    shop: shape({
      id: number.isRequired,
    }).isRequired,
  }),
};

LoginGA.defaultProps = {
  experiment: '',
  errors: [],
  recaptcha: {},
  mercadoShopsData: null,
};

module.exports = LoginGA;
