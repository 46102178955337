import semverGte from 'semver/functions/gte';
import { FaceValidationVersionSupported, PLATFORM, OS, MinVersionSupportedForWK2, CHALLENGES, VIEW_MODES } from '../data/enums';

export const validateVersionSupported = (version, platformId) => {
  if (!version) return false;
  if (PLATFORM[platformId]) return semverGte(version, FaceValidationVersionSupported[platformId.toUpperCase()]);

  return false;
};

/**
 * @param {import('frontend-device_detection').UserDevice} device
 */
export const isSupportedForWebkit2 = (device) => {
  const { nativeApp, webviewUserAgent } = device;

  if (nativeApp?.os !== OS.Android) return false;

  const { major, minor, patch } = webviewUserAgent;

  /** In some cases, we got webviewUserAgent properties with undefined */
  return !major || semverGte(
    `${major}.${minor}.${patch}`,
    MinVersionSupportedForWK2.Webview.Android,
  );
};

export const isSocialLogin = (type) => type === CHALLENGES.email_or_nickname_or_phone_or_social_login || type === CHALLENGES.email_or_nickname_or_phone_or_social_login_with_recaptcha;

export const isV2LoginViewMode = viewMode => viewMode === VIEW_MODES.landscape;
