import React from 'react';
import { Script } from 'nordic/script';
import PropTypes from 'prop-types';

export const RecaptchaScript = (props) => {
  const { action, envIsProduction, keys, required, shouldRunScore = true, url } = props;

  return (
    <Script>
      {`window.onloadCallback = function() {
        if(grecaptcha && ${shouldRunScore}) {
          // Cargo el script
          document.getElementById('gctkn').value = 'script-loaded';
          try{
            grecaptcha.enterprise
              .execute('${keys.version3}', {action: '${action}'})
                .then(function(token) {
                  if(grecaptcha && ${required}) {
                    grecaptcha.enterprise.render('g-recaptcha', {
                      'sitekey': '${keys.version2}'
                    });
                  }
                  document.getElementById('gctkn').value = token;
                  const spinnerEl = document.querySelector('.recaptcha__spinner');
                  if (spinnerEl) {
                    spinnerEl.classList.add('recaptcha__spinner--hide');
                  }
              });
          }catch(e){
            // fallo el execute de recaptcha
            document.getElementById('gctkn').value = 'execution-failure';
            if(grecaptcha && ${required}) {
              if(${envIsProduction}) {
                grecaptcha.enterprise.render('g-recaptcha', {
                  'sitekey': '${keys.version2}'
                });
              } else {
                grecaptcha.render('g-recaptcha', {
                  'sitekey': '${keys.version2}'
                });
              }
            }
          }
        }
      }
      var recaptchaScript = document.createElement('script');
      recaptchaScript.src = '${url}';
      recaptchaScript.defer = 1;
      recaptchaScript.async = 1;
      document.head.appendChild(recaptchaScript);
      recaptchaScript.onerror = () => {
        // Error al cargar
        document.getElementById('gctkn').value = 'script-error';
      }`}
    </Script>
  );
};

RecaptchaScript.propTypes = {
  action: PropTypes.string,
  envIsProduction: PropTypes.bool,
  keys: PropTypes.shape({
    version2: PropTypes.string,
    version3: PropTypes.string,
  }),
  required: PropTypes.bool,
  shouldRunScore: PropTypes.bool,
  url: PropTypes.string,
};
