const React = require('react');
const { shape, string, bool } = require('prop-types');
const MeliGA = require('nordic/analytics/meli-ga');
const Script = require('nordic/script');

// LoginTypes
const CHECKOUT_BUY = 'checkout_buy';
const CHECKOUT_AUCTION = 'checkout_auction';

// Steps
const REGISTRATION = 'REGISTRATION';
const PASSWORD = 'PASSWORD';

// Dedicated Google Analytics tracking for checkout contexts (CHECKOUT_BUY/CHECKOUT_AUCTION)
// needed for BA metrics
class CheckoutGA extends React.Component {
  isCheckoutContext(loginType) {
    return loginType.indexOf(CHECKOUT_BUY) !== -1 || loginType.indexOf(CHECKOUT_AUCTION) !== -1;
  }

  trackBuyIntentionEventScript(step, loginType) {
    const { firstStep } = this.props;
    // avoid duplicate tracking throughout login steps
    const isFirstTimeTracking = step === REGISTRATION || (step === PASSWORD && firstStep);
    if (isFirstTimeTracking) {
      const label = loginType === CHECKOUT_BUY ? 'BUY-IT-NOW' : 'AUCTION';
      const script = `meli_ga('send', 'event', 'ITEM', 'BUY-INTENTION', '${label}', {'nonInteraction': true})`;

      return <Script>{script}</Script>;
    } else {
      return null;
    }
  }

  fillGAPropsForStep(step, gaProps) {
    const { mobile } = this.props;
    const deviceType = mobile ? 'MOBILE' : 'DESKTOP';
    const section = 'BUYING_FLOW';
    let page = `LOGIN/${step}`;
    if (step === REGISTRATION) {
      page = 'REGISTRATION';
    }
    gaProps.section = section;
    gaProps.page = page;
    gaProps.privatePage = `${deviceType}/${page}`; // dimension 12
  }

  render() {
    const { navigation: { site_id, platform_id, login_type }, step } = this.props;
    const loginType = login_type.toLowerCase();
    if (this.isCheckoutContext(loginType)) {
      const gaProps = {
        business: 'MARKETPLACE',
        vertical: 'CORE',
        siteId: site_id.toUpperCase(),
        platform: platform_id.toUpperCase(),
      };
      this.fillGAPropsForStep(step, gaProps, loginType);

      return (
        <div>
          <MeliGA {...gaProps} />
          {this.trackBuyIntentionEventScript(step, loginType)}
        </div>
      );
    } else {
      return null;
    }
  }
}

CheckoutGA.propTypes = {
  firstStep: bool,
  navigation: shape().isRequired,
  step: string.isRequired,
  mobile: bool.isRequired,
  experiment: string,
};

CheckoutGA.defaultProps = {
  firstStep: false,
  experiment: '',
};

module.exports = CheckoutGA;
