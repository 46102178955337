const React = require('react');
const Style = require('nordic/style');
const { string } = require('prop-types');

const StyleAsset = ({ platformId, viewName }) => {
  const id = platformId.toLowerCase();
  const extension = id === 'mp' || id === 'ms' ? `_${id}` : '';

  return <Style href={`${viewName}${extension}.css`} inline />;
};

StyleAsset.propTypes = {
  platformId: string.isRequired,
  viewName: string.isRequired,
};

module.exports = StyleAsset;
