const React = require('react');
const { shape, bool, string, arrayOf, func, oneOfType } = require('prop-types');
const Textfield = require('@andes/textfield');

class EmailInput extends React.Component {
  handleChange(value) {
    const { onChange } = this.props;
    if (onChange) {
      onChange(value);
    }
  }

  handleKeyUp(event) {
    const { onKeyUp } = this.props;
    if (onKeyUp) {
      onKeyUp(event);
    }
  }

  render() {
    const { type, name, value, placeholder, autocomplete, label, message, errors, suggestions } = this.props;

    return (
      <div className="login-form__input">
        <Textfield
          type={type}
          className="login-form__input--email"
          id={name}
          data-testid={name}
          name={name}
          value={value}
          onChange={e => this.handleChange(e.target.value)}
          onSelect={(e, v) => this.handleChange(v)}
          onKeyUp={e => this.handleKeyUp(e)}
          placeholder={placeholder || ''}
          autoComplete={autocomplete ? 'on' : 'off'}
          autoCapitalize="none"
          spellCheck="false"
          autoCorrect="off"
          label={label}
          messageShow
          message={message}
          modifier={errors.length > 0 ? 'error' : 'default'}
          suggestions={suggestions}
          autoFocus
        />
      </div>
    );
  }
}

EmailInput.propTypes = {
  type: string,
  name: string.isRequired,
  placeholder: string,
  label: string,
  autocomplete: bool,
  value: string,
  message: string.isRequired,
  errors: arrayOf(shape({
    text: oneOfType([string, shape()]),
  })),
  onChange: func,
  onKeyUp: func,
  suggestions: arrayOf(shape({
    name: string,
  })),
};

EmailInput.defaultProps = {
  type: 'email',
  placeholder: '',
  label: '',
  autocomplete: false,
  value: undefined,
  errors: [],
  onChange: null,
  onKeyUp: null,
  suggestions: [],
};

module.exports = props => <EmailInput {...props} />;
