/* eslint-disable no-undef */
import { LoggerFactory } from 'nordic/logger';

const logger = LoggerFactory('melidata-track');

/**
 * @typedef LoginAuthChallengeEventData
 * @prop  {String} challenge
 * @prop  {String} tracking_id
 * @prop  {String} source
 * @prop  {String} [reauthentication]
 * @prop  {String} [version]
 * @prop  {String} [code]
 */

/**
 * @param  {Object} data
 * @param  {string} data.path
 * @param  {Object} data.event_data
 */

export const trackEvent = (data = {}) => {
  const { path, application, event_data } = data;
  try {
    if (typeof melidata === 'function') {
      const track = {};
      melidata('createEventTrack', track);
      if (path) melidata('withPath', data.path, track);
      if (application) melidata('withApplicationData', data.application, track);
      if (event_data) melidata('withData', data.event_data, track);
      melidata('sendTrack', track);
    }
  } catch (e) {
    logger.warn(`Error sending track for Melidata: ${e}`);
  }
};
