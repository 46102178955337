export const TRACK_KEY = {
  CHANGE_ACCOUNT: 'changeAccount',
  HELP: 'help',
  SECURITY_PROBLEM: 'securityProblem',
  PASSWORD_VISIBILITY: 'passwordVisibility',
  GOOGLE_AUTH_CLICK: 'googleAuthClick',
  CREATE_ACCOUNT: 'createAccount',
  IDENTIFICATION: 'identification',
  PASSWORD: 'password',
  SUBMIT: 'submit',
  DECLINE: 'decline',
  ERROR: 'error',
};
