import React from 'react';
import PropTypes from 'prop-types';

export const ErrorIcon = ({ namespace }) => (
  <svg className={`${namespace}__error-icon`} width="12" height="12" viewBox="0 0 12 12">
    <defs>
      <rect id="a" width="12" height="12" rx="6" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <g mask="url(#b)">
        <path
          fill="#F23D4F"
          d="M-.686-.343h13.371v12.686H-.685z"
        />
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M6 7.636a.727.727 0 1 1 0 1.455.727.727 0 0 1 0-1.455zm.727-4.727l-.182 4h-1.09l-.182-4h1.454z"
        />
      </g>
    </g>
  </svg>
);

ErrorIcon.propTypes = {
  namespace: PropTypes.string,
};
