/* globals document */

const React = require('react');
const { string } = require('prop-types');

// */login, optional /index, optional trailing slash & query string
const loginEndpointRegex = /.*\/login(\/index)?(\/)?(\?[^/]*)?$/; // eslint-disable-line security/detect-unsafe-regex
const authenticationEndpointRegex = /.*\/authentication\/.*/;

/*
  Changes the current URL of a view if it's not related to a challenge (/login, /login?...)
  but is actually displaying one.
*/
class ReplaceUrl extends React.Component {
  componentDidMount() {
    const { url } = this.props;
    if (this.applies(document.URL) && window.history && window.history.replaceState) {
      window.history.replaceState({}, 'view', url);
    }
  }

  applies(url) {
    return url.match(loginEndpointRegex) || url.match(authenticationEndpointRegex);
  }

  render() {
    return null;
  }
}

ReplaceUrl.propTypes = {
  url: string.isRequired,
};

module.exports = ReplaceUrl;
