export const getGoogleAuthButtonConfig = ({ locale, width, handleClickListener }) => ({
  type: 'standard',
  size: 'large',
  theme: 'outline',
  locale: locale?.split('-')[0],
  shape: 'rectangular',
  logo_alignment: 'left',
  width,
  text: 'signin_with',
  click_listener: handleClickListener,
});
