/* eslint-disable security/detect-non-literal-regexp */
const React = require('react');
const {
  regex: { allMaskedText, maskedTextsAroundTheCursor },
} = require('../../../../services/utils/textTools');

const { useState, useRef } = React;

const TIME_TO_HIDE = 3000;

const defaultConfig = {
  mode: 'lastCharacter',
  characterMask: '•',
  initialValue: '',
  visibleTime: TIME_TO_HIDE,
};

const useTextFieldMask = (mainHandlers, initialValue = '', config = defaultConfig) => {
  const [obfuscatedValue, setObfuscatedValue] = useState('');
  const [rawValue, setRawValue] = useState(initialValue);

  const timer = useRef();
  const inputRef = useRef(null);
  const cursorPos = useRef(0);

  const getNewUnmaskedInput = (
    prevUnmasked,
    currentWithMask,
    cursorPosition,
    maskToMatch,
  ) => currentWithMask
    .replace(
      cursorPosition
        ? maskedTextsAroundTheCursor(maskToMatch, cursorPosition)
        : allMaskedText(maskToMatch),
      (match, _, offset) => {
        if (!offset && cursorPosition) {
          return prevUnmasked.substr(0, match.length);
        }

        return prevUnmasked.substr(-match.length);
      },
    );

  const obfuscateInput = (value, characterMask, currentCursorPosition = null) => value
    .split('')
    .map((character, index) => (
      currentCursorPosition !== null && index === currentCursorPosition - 1 ? character : characterMask
    ))
    .join('');

  const clear = (old, newWord) => {
    const oldReverse = old.split('').reverse();
    const parse = (nextWord, previousWord) => nextWord
      .map((char, idx) => (
        (char !== config.characterMask
          && previousWord[idx] === char
          && config.characterMask
        )
          || char
      ));

    const leftToRight = parse(newWord.split(''), old).join('');
    const rightToLeft = parse(newWord.split('').reverse(), oldReverse).reverse().join('');

    return (leftToRight !== newWord && leftToRight) || (rightToLeft !== newWord && rightToLeft) || newWord;
  };

  const changeLater = (value) => {
    if (timer.current) clearTimeout(timer.current);
    timer.current = setTimeout(
      () => {
        setObfuscatedValue(obfuscateInput(value, config.characterMask));
      },
      config.visibleTime,
    );
  };

  const interceptInput = (value) => {
    const inputValue = clear(obfuscatedValue, value);

    const newRawValue = getNewUnmaskedInput(
      rawValue,
      inputValue,
      cursorPos.current,
      config.characterMask,
    );

    const newHiddenValue = obfuscateInput(inputValue, config.characterMask, cursorPos.current);

    changeLater(newRawValue);
    setObfuscatedValue(newHiddenValue);
    setRawValue(newRawValue);

    return { newRawValue, newHiddenValue };
  };

  const onChange = (event) => {
    inputRef.current = event.target;
    cursorPos.current = inputRef.current.selectionEnd || 0;
    const { newHiddenValue, newRawValue } = interceptInput(event.target.value);
    if (mainHandlers.onChange) {
      mainHandlers.onChange(newRawValue, newHiddenValue);
    }
  };

  const onKeyUp = (event) => {
    cursorPos.current = event.target.selectionEnd;
    if (mainHandlers.onKeyUp) {
      mainHandlers.onKeyUp(event);
    }
  };

  const onClick = (event) => {
    cursorPos.current = event.target.selectionEnd;
    if (mainHandlers.onClick) {
      mainHandlers.onClick(event);
    }
  };

  React.useEffect(() => {
    if (inputRef.current?.setSelectionRange) {
      inputRef.current.setSelectionRange(cursorPos.current, cursorPos.current);
    }
  }, [obfuscatedValue, inputRef, cursorPos]);

  React.useEffect(() => {
    if (initialValue) {
      inputRef.current = { value: initialValue };
      cursorPos.current = initialValue.length || 0;
      const newHiddenValue = obfuscateInput(initialValue, config.characterMask, cursorPos.current);

      changeLater(initialValue);
      setObfuscatedValue(newHiddenValue);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [
    rawValue,
    obfuscatedValue,
    {
      onChange,
      onKeyUp,
      onClick,
    },
  ];
};

const withMaskedValue = (TextFieldComponent) => ({
  onChange,
  onKeyUp,
  onClick,
  initialValue,
  ...inputProps
}) => {
  const mainHandlers = {
    onChange,
    onKeyUp,
    onClick,
  };

  const [, valueWithMask, newHandlers] = useTextFieldMask(mainHandlers, initialValue);

  return (
    <TextFieldComponent
      {...inputProps}
      onChange={newHandlers.onChange}
      value={valueWithMask}
      onKeyUp={newHandlers.onKeyUp}
      onClick={newHandlers.onClick}
    />
  );
};

module.exports = {
  useTextFieldMask,
  withMaskedValue,
};
