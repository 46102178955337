const React = require('react');
const PropTypes = require('prop-types');
const { Script } = require('nordic/script');

const ProfilingScriptContainer = ({ profiling }) => (
  <>
    <Script className="profiling-container" priority={200}>
      {profiling.widget}
    </Script>
    <noscript>{profiling.noScript}</noscript>
  </>
);

ProfilingScriptContainer.propTypes = {
  profiling: PropTypes.shape({
    widget: PropTypes.string,
  }),
};

module.exports = ProfilingScriptContainer;
